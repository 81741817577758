import React, { FC } from "react";

import { RouteComponentProps } from "@reach/router";

import { Link } from "../components/Link";
import { PublicRoute } from "../components/PublicRoute";
import { SEO } from "../components/Seo";
import { H1, Typography } from "../components/Typography";
import { Paths } from "../paths";

const DesktopApp: FC<RouteComponentProps> = (props) => {
  return (
    <PublicRoute {...props}>
      <SEO title="Faq" />
      <div className="flex flex-1 w-full flex-col max-w-screen-xl mx-auto px-12">
        <div className="flex-1 flex flex-col justify-center order-first lg:order-last sm:w-10/12 md:w-8/12 lg:w-6/12">
          <H1 className="mb-12" style={{ fontSize: 54 }}>
            Desktop App
          </H1>

          <Typography>
            We are working on a beta local version, and are looking for two more pilot program partners. If you are
            interested in using Colorline on your firm’s local system, <Link active to={Paths.Contact}>contact us</Link>
          </Typography>
        </div>
      </div>
    </PublicRoute>
  );
};

export default DesktopApp;
