import { isNil } from "ramda";
import { selector } from "recoil";

import { meState } from "../contexts/me";

export const authorizedSelector = selector({
  key: "authorized",
  get: ({ get }) => {
    const me = get(meState);

    return !isNil(me);
  },
});

export const emailSelector = selector({
  key: "loggedInEmail",
  get: ({ get }) => {
    const me = get(meState);

    return me ? me.email.split("@")[0] : "User";
  },
});

export const idSelector = selector({
  key: "userId",
  get: ({ get }) => {
    const me = get(meState);

    return me?.id;
  },
});
