import { api, createAuthorizationHeader } from "../utils/api";

export interface Me {
  caseCount: number;
  email: string;
  id: number;
  verified: boolean;
}

export async function getMe(): Promise<Me> {
  const response = await api.request<{ body: Me }>("/users/me", {
    requiresAuthentication: true,
    method: "GET",
  });

  return response.body;
}

export async function session(): Promise<void> {
  let refreshToken = null;

  try {
    const tokens = await api.getToken();
    refreshToken = tokens.refreshToken;
    const response = await api.request<{ body: { refresh_token: string; access_token: string } }>("/token/refresh", {
      requiresAuthentication: true,
      method: "POST",
      headers: createAuthorizationHeader(refreshToken!),
    });
    await api.setToken({
      token: {
        accessToken: response.body.access_token,
        refreshToken: response.body.refresh_token,
      },
    });
  } catch (err) {
    if (!refreshToken) throw new Error("Inexistent token");
    throw new Error(err.message);
  }
}

export type LoginInput = { email: string; password: string };

export async function login({ email, password }: LoginInput): Promise<void> {
  const response = await api.request<{
    body: {
      access_token: string;
      refresh_token: string;
    };
  }>("/auth", {
    requiresAuthentication: false,
    method: "POST",
    body: { email, password },
  });
  await api.setToken({
    token: {
      accessToken: response.body.access_token,
      refreshToken: response.body.refresh_token,
    },
  });
}

export type SignupInput = { email: string; password?: string };

export async function signup({ email, password }: SignupInput): Promise<void> {
  await api.request<{
    body: {
      access_token: string;
      refresh_token: string;
    };
  }>("/users", {
    requiresAuthentication: false,
    method: "POST",
    body: { email, password },
  });
}

export async function logout(): Promise<void> {
  await api.request<void>("/logout", {
    requiresAuthentication: true,
    method: "POST",
  });

  await api.removeToken();
}

export async function verifyAccount(token: string): Promise<void> {
  await api.request<void>(`/verification/${token}`, {
    method: "post",
  });
}
