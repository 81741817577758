import React, { FC, useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";

import { RouteComponentProps } from "@reach/router";

import { getMe, logout, session } from "../api/me";
import { meState, UserContext } from "../contexts/me";
import { authorizedSelector } from "../selectors/me";
import { Layout } from "./Layout";

export const PublicRoute: FC<RouteComponentProps> = (props) => {
  const { children } = props;
  const authorized = useRecoilValue(authorizedSelector);
  const [, setMe] = useRecoilState(meState);

  useEffect(() => {
    const refresh = async () => {
      try {
        await session();
        const me = await getMe();
        setMe(me);
      } catch (err) {
        if (err.message !== "Inexistent token") {
          await logout();
          setMe(null);
        }
      }

      return true;
    };

    refresh();
  }, []);

  return (
    <UserContext.Provider value={{ authenticated: authorized }}>
      <Layout isAuthenticated={authorized}>{children}</Layout>
    </UserContext.Provider>
  );
};
